<template>
  <div class="help">
    <div class="title">Правила игры</div>
    <div class="container">

      <template v-for="id in queue">
        <div 
          v-if="paragraphs[id]"
          :key="id"
          class="bb"
        >
          <div class="bb_title">
            <span 
              v-if="paragraphs[id].title"
              v-text="paragraphs[id].title"
            />
            <span 
              v-if="paragraphs[id].icon"
              @click="invoke(paragraphs[id].icon)"
            >
              <img 
                style="padding-left: 10px"
                :src="paragraphs[id].icon.src"
              />
            </span>
          </div>
          <div 
            class="bb_text"
            v-text="paragraphs[id].text"
          />
        </div>

      </template>
      <div class="bb">
          <div class="bb">
            <a  v-if="isDefaultUser"
                class="bb_link"
                target="_blank"
                v-text="'Пользовательское соглашение'"
                :href="offers.actual[2].url"
            />
            <a  v-else
                class="bb_link"
                target="_blank"
                v-text="'Пользовательское соглашение '"
                :href="offers.actual[1].url"
            />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'HelpMain',

  data: () => ({
    lodash: _,
    queue: [],
    paragraphs: {},
    offers: {},
  }),

  computed: {
    ...mapGetters(['token', 'isDefaultUser']),
  },

  async mounted() {
    try {
      const headers = {};
      headers.token = this.token;
      const response = await axios.get('/api/help', { headers });
      this.queue = response.data.queue;

      //удаляем пункт стоимость для не мегафона
      if(this.isDefaultUser){
        this.queue = _.omitBy(this.queue, x=>x == "price");
      }
      this.offers = response.data.offers;
      this.paragraphs = Object.keys(response.data.paragraphs).reduce((acc, p) => {
        acc[p] = response.data.paragraphs[p];
        if (acc[p].icon && acc[p].icon.src && !acc[p].icon.src.includes('://')) {
          const src = acc[p].icon.src.replace(/^\.+\//, '');
          acc[p].icon.src = window.location.origin + '/' + src;
        };
        return acc;
      }, {});
    } catch(err) {
      console.error(err);
    };
  },

  methods: {
    invoke({ click, args = [] }) {
      _.invoke(this, click, ...args);
    },
  },
};
</script>

<style lang="scss" scoped>
.help {
  padding-top: 22px;
  padding-bottom: 30px;
  padding-left: 35px;
  padding-right: 35px;
  color: #14142B;

  .title {
    color: #000;
    padding: 65px 0 0 0;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
  }

  .container {
    margin-top: 30px;

    .bb {
      &_title {
        font-size: 17px;
        margin-bottom: 10px;
        font-weight: 600;
      }
      &_text {
        font-weight: normal;
        font-family: 'Montserrat', sans-serif;
        text-align: left;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.75px;
        text-align: left;
        white-space: break-spaces;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &_link {
        text-decoration: none;
        color: #4791ffcf;
      }
      fieldset {
        padding: 10px;
        &:not(.offer-actual) {
          opacity: .9;
        }
        ol {
          list-style: disc;
          margin-left: 20px;
          gap: 5px;
          display: flex;
          flex-direction: column;
        }
      }
      & > fieldset {
        border: 1px solid;
        border-radius: 5px;
        padding: 15px;
        & > legend {
          font-weight: bold;
        }
      }
    }
  }
}
</style>