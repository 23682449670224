<template>
  <HelpMain />
</template>

<script>
import axios from "axios";
import HelpMain from "@/components/game/HelpMain";

export default {
  name: "Help",
  components: {
    HelpMain,
  },
};
</script>

<style>
</style>